import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-error" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", {
      innerHTML: _ctx.$t('headline_copy')
    }, null, 8, _hoisted_2),
    _createElementVNode("p", {
      innerHTML: _ctx.$t('body_copy')
    }, null, 8, _hoisted_3)
  ]))
}