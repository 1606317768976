
const getDefaultState = () => ({

});

const state = getDefaultState();

const getters = {
};

const mutations = {
    clear (state, actionName) {
        state[actionName] = false;
    },
    createOrUpdate (state, actionName) {
        state[actionName] = true;
    },
};

const actions = {
    turnOffAction ({ commit }, actionName) {
        commit('clear', actionName);
    },
    turnOnAction ({ commit }, actionName) {
        commit('createOrUpdate', actionName);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
