
    import MenuBar from '@components/MenuBar.vue';
    import PromoLinks from '@components/PromoLinks.vue';
    import TheFooter from '@components/TheFooter.vue';
    import { checkFeatureFlag } from '../feature-flags';

    export default {
        components: {
            TheFooter,
            MenuBar,
            PromoLinks,
        },
        methods: {
            checkFeatureFlag,
        },

        i18nOptions: { namespaces: ['global', 'landing'] },
    };
