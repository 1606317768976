
/* These are turned into rocketship-validation objects.
 * Information on that is located in the readme for that.
 * https://github.com/rocketship-core/validator
 */
const
    range = require('lodash/range'),
    isEmail = require('validator/lib/isEmail'),
    config = require('rocketship-config'),
    { isZip } = require('rocketship-validator'),
    avatars = {"_public":true,"avatars":["donut","bacon","creamer","to-go-hot-drink","croissant","muffin","bagged-coffee","iced-coffee","hot-coffee","refresher"]},
    { maxFields, displayCAConfirm } = {"_public":true,"maxFields":3,"displayCAConfirm":true},
    // This is used to protect downstream systems with databases that can't handle
    // characters wider than three bytes, like FE2 and HW Analytics.
    // https://jiradc.helloworld.com/browse/SCU-144
    /* eslint-disable-next-line no-control-regex -- We match every character
        less than 4 bytes wide here, which does intentionally include control
        characters.
    */
    no4ByteChars = /^[\u{000000}-\u{00FFFF}]*$/u,
    noEmail = /^((?!@).)*$/,
    // password must be at least 8-50 characters, and include at least one letter, one number, and one special character
    minLetters = /[a-zA-Z]/,
    minNums = /[0-9]/,
    specialChars = /[!@#$%^&*]/,
    minChars = /^.{8,50}$/,
    // birthday regex:
    // 0?[1-9] => 1-9 or 01-09
    // 1[0-2] => 10, 11, 12
    // 0?[1-9] => 1-9 or 01-09
    // [12][0-9] => 10-29
    // 3[01] => 30 or 31
    birthdate = /(0?[1-9]|1[0-2])-(0?[1-9]|[12][0-9]|3[01])/,
    isNumeric = /^[0-9]+$/;

module.exports = {
    landing: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
    },
    login: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
        plainTextPassword: {
            required: true,
        },
    },
    optout: {
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
    },
    register: {
        first_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        last_name: {
            required: true,
            no4ByteChars,
            noEmail,
        },
        email: {
            required: true,
            external: [isEmail],
            no4ByteChars,
        },
        age: {
            required: true,
            isChecked: true,
        },
        zip: {
            required: true,
            isZip,
            notPuertoRico,
        },
        password: {
            required: true,
            meetsPasswordRequirements,
        },
        confirm_password: {
            required: true,
            matchesField: 'password',
        },
    },
    passwordReset: {
        password: {
            required: true,
            meetsPasswordRequirements,
        },
        confirm_password: {
            required: true,
            matchesField: 'password',
        },
    },
    update: {
        first_name: {
            no4ByteChars,
            noEmail,
            notEmpty,
        },
        last_name: {
            no4ByteChars,
            noEmail,
            notEmpty,
        },
        birthdate: {
            birthdate,
            notEmpty,
        },
        avatar: {
            avatarRequirements (value, key) {
                // if the there is no value the avatar is not being updated and we can return true
                // if there is a value, check to make sure it's a valid value from avatars.json
                if (!value || Object.values(avatars.avatars).includes(value)) {
                    return true;
                }
                return false;
            },
            notEmpty,
        },
        primary_opt_in: {
            notEmpty,
        },
        zip: {
            isZip,
            notEmpty,
            notPuertoRico,
        },
    },
    markNotification: {
        notification_id: {
            required: true,
            no4ByteChars,
        },
    },
    addNotification: {
        type: {
            required: true,
            no4ByteChars,
        },
    },
    overlayRules: {
        rules: {
            required: true,
            isChecked: true,
        },
    },
    viral: {
        to_email1: {
            required: true,
            external: [isEmail],
            no4ByteChars,
            // These validators run on the first field, but are smart enough to check
            // for errors in all TO email addresses.
            // Note: `referringSelf` may only be validated client-side if `email` is a
            // public field in the profile config, and it's not by default.
            referringSelf (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj);

                const referredSelf = toEmailFields.find(([key, email]) => email === obj.selfEmail);

                if (referredSelf) {
                    const [selfKey] = referredSelf;
                    validation.addError(selfKey, 'REFERRED_SELF');
                    return false;
                }

                return true;
            },
            duplicateReferral (value, key, obj) {
                const
                    validation = this,
                    toEmailFields = getFilledToEmailFields(obj),
                    seen = {};

                const duplicate = toEmailFields.find(([key, email]) => {
                    if (email in seen) return true;
                    seen[email] = true;
                    return false;
                });

                if (duplicate) {
                    const [duplicateKey] = duplicate;
                    validation.addError(duplicateKey, 'DUPLICATE');
                    return false;
                }

                return true;
            },
        },
        to_name1: {
            required: true,
            no4ByteChars,
        },
    },
    faqContact: {
        first_name: {
            required: true,
        },
        email: {
            required: true,
            external: [isEmail],
        },
        question: {
            required: true,
        },
        issue_type: {
            required: true,
        },
    },
    birthDate: {
        month: {
            required: true,
        },
        day: {
            required: true,
        },
    },
    redeem: {
        reward_id: {
            required: true,
            isNumeric,
        },
    },
    adminAddress: {
        address1: {
            required: true,
            no4ByteChars,
        },
        city: {
            required: true,
            no4ByteChars,
        },
        state: {
            required: true,
            no4ByteChars,
        },
        zip: {
            required: true,
            isZip,
        },
        country: {
            required: true,
        },
    },
};

const viralGuards = module.exports.viral;

if (displayCAConfirm) {
    viralGuards.taf_confirm = { required: true, isChecked: true };
}

range(1, maxFields + 1).forEach((fieldNum) => {
    viralGuards['to_name' + fieldNum] = {
        ...viralGuards['to_name' + fieldNum],

        requiresField: 'to_email' + fieldNum,
    };
    viralGuards['to_email' + fieldNum] = {
        ...viralGuards['to_email' + fieldNum],

        requiresField: 'to_name' + fieldNum,
        external: [isEmail],
    };
});

function getFilledToEmailFields (obj) {
    return  Object.entries(obj)
    .filter(([key]) => key.startsWith('to_email'))
    // Filter out blanks.
    .filter(([key, email]) => !!email);
}

function notEmpty (value, key, obj) {
    if (value === undefined) return true;

    if (value === null) return false;

    const str = String(value);
    if (!str || str.match(/^\s*$/)) return false;

    return true;
}

function notPuertoRico (zip) {
    if (zip != undefined && zip.match(/^00[679]/)) {
        this.addError('ZIP', 'INELIGIBLE', 'failed notPuertoRico test');
        return false;
    }

    return true;
}

function meetsPasswordRequirements (value, key) {
    const requirements = { minChars, minLetters, minNums, specialChars };
    const failedReqs = Object.keys(requirements).filter(req => !value?.match(requirements[req]));

    if (failedReqs.length) {
        failedReqs.forEach((req) => this.addError(key, req, `failed ${req} test`));
        return false;
    }

    return true;
}

